import { NextPage } from "next";

import { LoadingModule } from "@/components/modules/loading";
import { useGlobalContext } from "@/hooks/use-global-context";

import { ListsModule } from "@/modules/pipeline/lists";
import { useSetLayoutParams } from "@/context/app-layout";

const LAYOUT_PARAMS = {
  title: "",
  meta: {
    title: "Open Pipeline - Glencoco",
  },
  pageContainerClassName: "bg-black/3 overflow-auto",
  // overflow-x-auto in the "contentContainerClassName" allows this page to
  // scroll horizontally like a notion table
  contentContainerClassName: "overflow-x-auto",
  headerClassName: "!max-w-[unset] px-0",
  isBackgroundVisible: false,
};

const HomepageWithDataFetched = () => {
  const { isUserLoaded } = useGlobalContext();

  return isUserLoaded ? <ListsModule /> : <LoadingModule />;
};

const Homepage: NextPage = () => {
  useSetLayoutParams(LAYOUT_PARAMS);

  return <HomepageWithDataFetched />;
};

export default Homepage;
